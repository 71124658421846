




import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import FscPageHeader from "@/components/FscPageHeader.vue";
import EmailTemplateCategories from "@/views/Communication/EmailTemplateCategories.vue";
import EmailPlaceholders from "@/components/EmailPlaceholders.vue";
import TemplateForm from "@/components/TemplateForm.vue";
import RemoveButton from "@/components/Button/RemoveButton.vue";
import CreateButton from "@/components/Button/CreateButton.vue";
import CreateCategoryButton from "@/components/Button/CreateCategoryButton.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import CardButtonForm from "@/components/CardButtonForm.vue";
import Actions from "@/components/Actions.vue";
import FuncMixins from "@/mixins/FuncMixins";
import FscModal from "@/components/Modal/FscModal.vue";
import CommunicationTemplates from "@/views/Communication/CommunicationTemplates.vue";

@Component({
  mixins: [FuncMixins],
  components: {
    CommunicationTemplates,
    CardButtonForm,
    FilterAndSearch,
    EmailPlaceholders,
    EmailTemplateCategories,
    FscPageHeader,
    RemoveButton,
    CreateButton,
    CreateCategoryButton,
    Actions,
    FscModal,
    TemplateForm,
  },
})
export default class EmailTemplates extends mixins(FuncMixins) {
  public name = "EmailTemplates";
}
