import { render, staticRenderFns } from "./EmailTemplateCategoryItem.vue?vue&type=template&id=2b7c505c&scoped=true"
import script from "./EmailTemplateCategoryItem.vue?vue&type=script&lang=ts"
export * from "./EmailTemplateCategoryItem.vue?vue&type=script&lang=ts"
import style0 from "./EmailTemplateCategoryItem.vue?vue&type=style&index=0&id=2b7c505c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b7c505c",
  null
  
)

export default component.exports